// Color pallette
$background: #EEEBD3;
$other-background: #437c90;
$gold: #a9874352;
.factoring-container {
  max-width: 500px;
  padding: 25px;
  overflow: scroll;
  background-color: $background;
  display: flex;
  flex-direction: column;
  .topbar{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    h2{
      text-align: center;
    }
    img{
      height: 40px;
      margin-right: 10px;
    }
  }
  .agente{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .singleSelect{
      width: 50%;
      border: none;
    }
  }

  .fechas{
    display: flex;
    color: black;
  }

  .summary{
    padding-left: 20px;
    padding-top: 25px;
    background-color: white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: left;
    
    table {
      // border-collapse: collapse;
      // margin: 0;
      margin-left: -10px;
      width: 100%;
      thead {
        background-color: $background;
      }
      
      th, td {
        padding: 10px;
        text-align: left;
      }
      
      th {
        font-weight: bold;
        border-bottom: 1px solid $gold;
      }
      
      tbody {
        background-color: $gold;
      }
      
    }
  }
}

label {
  display: block;
  margin-bottom: 10px;
}

// form for select options. 
input[type="number"],
input[type="date"],
select {
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 4px;
  appearance: none;
  margin-bottom: 10px;
}
  
button {
  width: 100%;
  padding: 8px 16px;
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
}
  
.filler{
  height:50vh;
  // width: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .fullLogo{
    width: 200px;
  }
}

